import React from "react";
import Seo from "../components/seo";
import { Container, Row, Col, Button } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
// import PdfIcon from "../images/pdf_icon.svg";
// import { Link } from "gatsby";

const TherapiemethodePage = () => {
  const [showSectionKlinghardt1, setShowSectionKlinghardt1] =
    React.useState(false);
  const [showSectionKlinghardt2, setShowSectionKlinghardt2] =
    React.useState(false);
  const [showSectionKlinghardt3, setShowSectionKlinghardt3] =
    React.useState(false);
  const [showSectionPhotonwave, setShowSectionPhotonwave] =
    React.useState(false);

  return (
    <Layout>
      <Seo
        title="Therapiemethoden"
        // description="Komplementäre Medizin ist ganzheitlich. Sie behandelt den ganzen Menschen – Körper, Geist und Seele – und nicht nur die Symptome."
      />

      <div style={{ position: "relative", zIndex: -1 }}>
        <Container className="g-0 position-absolute" fluid={true}>
          <Row className="g-0 justify-content-end">
            <Col xs={2} className="g-0">
              <StaticImage
                src="../images/blumentopf-cut-header.png"
                alt=""
                placeholder="tracedSVG"
                layout="fullWidth"
                sizes="33.3333333333vw"
                // formats={["auto", "webp", "avif"]}
                // sizes="(max-width: 575px) calc(50vw - 24px), (max-width: 576px) calc(540px - 24px), (max-width: 768px) calc(720px - 24px), (max-width: 992px) calc(960px - 24px), (max-width: 1200px) calc(1140px - 24px), calc(1320px - 24px)"
              />
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="mt-md-5 py-5">
        <Row className="justify-content-between align-items-end">
          <Col md={4}>
            <h4 className=" h5 text-secondary text-center text-md-center">
              Wie ernähren <br />
              Sie sich?
            </h4>
          </Col>
          <Col md={4}>
            <h4 className=" h5 text-quaternary text-end text-md-center mb-md-5">
              Beinträchtigt Sie <br />
              Elektrosmog?
            </h4>
          </Col>
          <Col md={4}>
            <h4 className=" h5 text-primary text-md-center">
              Fühlen Sie <br />
              sich gestresst?
            </h4>
          </Col>
        </Row>
        <Row className="justify-content-between align-items-center">
          <Col md={3}>
            <h4 className=" h5 text-tertiary text-tertiary-darker text-center text-md-center mb-5 mb-md-3">
              Wie oft treiben <br /> Sie Sport?
            </h4>
          </Col>
          <Col md={6}>
            <p className="text-center px-3 p-md-0 my-md-5">
              Es gibt hunderte Faktoren, die Wohlbefinden und Gesundheit negativ
              beeinflussen können. Schlechte Ernährung, Umweltgifte,
              Elektrosmog, Traumas, Allergien, Mangelerscheinungen, Stress usw.
              Und da wir keine Funktionsautomaten sind und jeder Mensch eine
              andere Konstitution und Robustheit besitzt, kann den einen
              umhauen, was dem anderen kaum schadet. Wir sind alle
              unterschiedlich und brauchen deshalb unterschiedliche
              Unterstützung für unser Wohlbefinden. Grundsätzlich gilt aber: Ist
              ein Mensch in seinem gesunden Gleichgewicht, kann er viel
              tolerieren. Gerät das Gleichgewicht aber über längere Zeit aus der
              Balance durch oben genannte Faktoren, läuft das Fass irgendwann
              über. Man fühlt sich schlapp, kränklich, müde und verträgt nichts
              mehr. Anstatt Symptome mit Medikamenten ruhig zu stellen, gilt es
              jetzt herauszufinden, welches die verantwortlichen, krankmachenden
              Faktoren sind und wie die Selbstheilungskräfte wieder aktiviert
              werden können. Ich helfe Ihnen gerne dabei.
            </p>
          </Col>
          <Col md={3}>
            <h4 className=" h5 text-secondary text-center text-md-center mt-5 mt-md-3">
              Haben <br /> Sie Sorgen, <br /> Ängste?
            </h4>
          </Col>
        </Row>
        <Row className="justify-content-between align-items-start">
          <Col md={4}>
            <h4 className=" h5 text-primary text-end text-md-center">
              Wie oft sind <br /> Sie an der <br /> frischenLuft?
            </h4>
          </Col>
          <Col md={4}>
            <h4 className=" h5 text-tertiary text-tertiary-darker text-md-center mt-md-5">
              Wie vielen <br /> Umweltgiften sind <br /> Sie ausgesetzt?
            </h4>
          </Col>
          <Col md={4}>
            <h4 className=" h5 text-quaternary text-center text-md-center">
              Belasten Sie <br /> traumatische <br /> Erfahrungen in Ihrem
              <br /> Alltag?
            </h4>
          </Col>
        </Row>
      </Container>

      <Container className="py-5">
        <Row>
          <Col>
            <h1 className="h2 section-title">Therapiemethoden</h1>
          </Col>
        </Row>
        <Row className="d-flex align-items-stretch g-3 g-lg-4 my-3">
          <Col xs={12} className="d-flex align-items-stretch">
            <Container className="g-0 d-flex align-items-stretch bg-quinary">
              <Row className="g-0 w-100" id="klinghardt">
                <Col xl={6} className="d-flex align-items-stretch">
                  <StaticImage
                    src="../images/WEM_1975.jpg"
                    alt=""
                    placeholder="blurred"
                    objectFit="cover"
                    objectPosition="50% 50%"
                    className="w-100"
                    // breakpoints={[
                    //   228, 258, 273, 296, 318, 348, 351, 366, 456, 516, 546,
                    //   592, 636, 696, 702, 732,
                    // ]}
                    layout="fullWidth"
                    // formats={["auto", "webp", "avif"]}
                    // aspectRatio={1}
                    // sizes="(max-width: 575px) calc(100vw - 24px), (max-width: 767px) 258px, (max-width: 991px) 348px, (max-width: 1199px) 228px, (max-width: 1399px) 273px, 318px"
                  />
                </Col>

                <Col xl={6}>
                  <div className="p-3 p-lg-4">
                    <h3 className="h4">
                      Neurobiologie nach Dr. med. Klinghardt
                    </h3>
                    <p>
                      Die Neurobiologie umfasst drei ganzheitliche Methoden, die
                      vom deutschen Arzt Dr. med. Dietrich Klinghardt in den USA
                      entwickelt wurden: Die Psycho-Kinesiologie (PK), der
                      Autonome Autonome Regulationstest (A.R.T.) und die
                      Mentalfeld-Therapie (MFT).
                    </p>
                    <ol>
                      <li>
                        <p>
                          Der <b>Autonome Regulationstest (A.R.T.)</b> ist ein
                          Untersuchungssystem, das die tiefer liegenden Ursachen
                          von körperlichen und seelischen Erkrankungen sichtbar
                          macht. Krankhafte Toxine, blockierte Nervenleitbahnen
                          und pathologische Gewebeveränderungen können ebenso
                          aufgedeckt und gezielt behandelt werden wie Traumas,
                          einschränkende Glaubenssätze und seelische Konflikte.
                        </p>
                        <Button
                          onClick={() =>
                            setShowSectionKlinghardt1(!showSectionKlinghardt1)
                          }
                          className="mb-3 custom-small-button"
                          variant="outline-primary"
                          size="sm"
                        >
                          {showSectionKlinghardt1 ? "Weniger" : "Mehr"}
                        </Button>
                        {showSectionKlinghardt1 && (
                          <>
                            <p>
                              Die Basis der neurobiologischen Arbeit ist der
                              Autonome Response Test (ART), bei dem zu Beginn
                              einer Behandlung durch einen Muskeltest
                              festgestellt werden kann, ob das Autonome
                              Nervensystem regulationsfähig ist, oder ob eine
                              oder mehrere Blockaden vorliegen. Durch diese Art
                              des Testens, die auf wissenschaftlichen Grundlagen
                              beruht, wird der Körper als Bio-Feedback-Messgerät
                              eingesetzt.
                            </p>
                          </>
                        )}
                      </li>

                      <li>
                        <p>
                          Bei der <b>Psycho-Kinesiologie (PK)</b> steht die
                          Psyche im Vordergrund. Nach einem eingehenden Gespräch
                          wird über den kinesiologischen Test ein Dialog mit dem
                          Unterbewusstsein des Klienten geführt. Dabei werden
                          ursprünglich traumatische Ereignisse, die verdrängt
                          worden sind, ans Licht gebracht und gelöst.
                        </p>
                        <Button
                          onClick={() =>
                            setShowSectionKlinghardt2(!showSectionKlinghardt2)
                          }
                          className="mb-3 custom-small-button"
                          variant="outline-primary"
                          size="sm"
                        >
                          {showSectionKlinghardt2 ? "Weniger" : "Mehr"}
                        </Button>
                        {showSectionKlinghardt2 && (
                          <>
                            <p>
                              Durch das Wiedererinnern eines Traumas können sich
                              die damit verbundenen, aufgestauten Gefühle
                              entladen und «entkoppelt» werden. Bei jedem
                              traumatischen Ereignis entstehen in unserem
                              Unterbewusstsein einschränkende Glaubenssätze, die
                              lebenslang gespeichert werden. Sätze wie „Ich bin
                              nicht liebenswert“ oder „Ich bin schwach“
                              beeinflussen unser Verhalten im täglichen Leben.
                              Je mehr einschränkende Glaubenssätze durch unser
                              Unterbewusstsein wirken, desto mehr sucht der
                              Körper einen Ausweg in Form von chronischen
                              Beschwerden und Schmerzzuständen aller Art.
                              Zusätzlich kann der Körper über das Bewusstmachen
                              und Entkoppeln von ungelösten Konflikten
                              Giftstoffe und pathogene Keime besser loswerden.
                              Ziel einer jeden Behandlung ist es, die
                              krankmachenden Glaubenssätze durch neue,
                              freimachende zu ersetzen. Auf diese Weise ist eine
                              dauerhafte Ausheilung ohne Symptomverschiebung
                              möglich.
                            </p>
                          </>
                        )}
                      </li>

                      <li>
                        <p>
                          <b>Mentalfeld-Technik (MFT)</b> Diese
                          Klopfakupressur-Technik beruht auf der einfachen
                          Erkenntnis, dass seelische Spannungszustände und
                          Schmerzen oft dann entstehen, wenn sich der
                          Mentalkörper als Folge eines Traumas vom physischen
                          Körper trennt. Sie findet Anwendung in der Behandlung
                          von akuten und chronischen Stresszuständen, unabhängig
                          davon, ob der Ursprung im körperlichen oder seelischen
                          Bereich liegt - ebenso in der Psycho-Traumatologie, in
                          der Behandlung von Ängsten, Phobien, Zwängen,
                          Prüfungsangst, Hyperaktivität, bei chronischen
                          Schmerzen, Allergien, bei der Narbenentstörung usw..
                        </p>
                        <Button
                          onClick={() =>
                            setShowSectionKlinghardt3(!showSectionKlinghardt3)
                          }
                          className="mb-3 custom-small-button"
                          variant="outline-primary"
                          size="sm"
                        >
                          {showSectionKlinghardt3 ? "Weniger" : "Mehr"}
                        </Button>
                        {showSectionKlinghardt3 && (
                          <>
                            <p>
                              Die Behandlung besteht darin, den abgespaltenen
                              Inhalt des Mentalfeldes durch ein kurzes,
                              klärendes Gespräch aufzudecken. Während der Klient
                              seine innere Aufmerksamkeit dem abgespaltenen
                              Inhalt zuwendet, beklopft der Behandelnde - oder
                              später der Klient selber - Akupunkturpunkte und
                              relevante autonome Schaltstellen, die den
                              Mentalkörper wieder anhaften und zentrieren.
                              Dadurch entstehen oft Heilungen innerhalb von
                              Minuten, die meist bleibend sind. Viele Ärzte,
                              Zahnärzte und deren Hilfspersonal in den USA haben
                              diese Methode von Dr. Klinghardt gelernt und
                              wenden sie erfolgreich bei vielen Patienten an:
                              Zahnschmerzen werden in Minuten bleibend
                              gemindert, die Angst vor der Injektion wird
                              ausgelöscht, Muskeln entspannen sich, Krebsmittel
                              und Herzmedikamente werden wieder vertragen.  Wut
                              und Traurigkeit werden in Minuten bleibend
                              abgebaut, Depressionen aufgelöst, Ischias und
                              Rückenschmerzen verschwinden ohne Injektion oder
                              Medikament, und vieles mehr.
                            </p>
                          </>
                        )}
                      </li>
                    </ol>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>

          <Col xs={12} className="d-flex align-items-stretch">
            <Container className="g-0 d-flex align-items-stretch bg-tertiary">
              <Row className="g-0 w-100" id="energiemedizin">
                <Col xl={6} className="d-flex align-items-stretch">
                  <StaticImage
                    src="../images/WEM_1941.jpg"
                    alt=""
                    placeholder="blurred"
                    objectFit="cover"
                    objectPosition="50% 50%"
                    className="w-100"
                    // breakpoints={[
                    //   228, 258, 273, 296, 318, 348, 351, 366, 456, 516, 546,
                    //   592, 636, 696, 702, 732,
                    // ]}
                    layout="fullWidth"
                    // formats={["auto", "webp", "avif"]}
                    // aspectRatio={1}
                    // sizes="(max-width: 575px) calc(100vw - 24px), (max-width: 767px) 258px, (max-width: 991px) 348px, (max-width: 1199px) 228px, (max-width: 1399px) 273px, 318px"
                  />
                </Col>

                <Col xl={6}>
                  <div className="p-3 p-lg-4">
                    <h3 className="h4">
                      Biophotonen­&shy;therapie mit dem CHIREN
                    </h3>
                    <p>
                      Das von Johan Boswinkel entwickelte, energiemedizinische
                      Biophotonen-Gerät Chiren basiert auf den Erkenntnissen des
                      Biophysikers Prof. Fritz Albert Popp (LINK), der
                      nachweisen konnte, dass jede Zelle Lichtteilchen
                      (Photonen) abgibt und aufnimmt. Diese ultraschwache
                      Zellstrahlung (Biophotonenstrahlung) enthält Informationen
                      über den Gesundheitszustand jedes einzelnen Organsystems.
                      Mit dem Chiren lässt sich über die Biophotonenpunkte =
                      Meridian- oder Akupunkturpunkte (an Händen und Füssen)
                      feststellen, wo im Körper die Regulationsvorgänge gestört
                      sind, wo die Zellstrahlung chaotisch = krankmachen ist und
                      wo sie kohärent = gesund ist. Der Energiefluss im gesamten
                      Meridiansystem kommt dadurch mehr in Balance, was
                      psychisch als Gefühl der Leichtigkeit empfunden wird. Die
                      verbesserte Zellkommunikation regt alle Organe an und
                      erleichtert dem Körper die Ausscheidung von
                      Giftstoffen. Werden über mehrerer Sitzungen die
                      harmonischen Schwingungen im Körper verstärkt und
                      disharmonische neutralisiert, werden die
                      Selbstheilungskräfte aktiviert und enorm verbessert.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>

          <Col xs={12} className="d-flex align-items-stretch">
            <Container className="g-0 d-flex align-items-stretch bg-quaternary">
              <Row className="g-0 w-100" id="photonwave">
                <Col xl={6} className="d-flex align-items-stretch">
                  <StaticImage
                    src="../images/WEM_1953.jpg"
                    alt=""
                    placeholder="blurred"
                    objectFit="cover"
                    objectPosition="50% 50%"
                    className="w-100"
                    // breakpoints={[
                    //   228, 258, 273, 296, 318, 348, 351, 366, 456, 516, 546,
                    //   592, 636, 696, 702, 732,
                    // ]}
                    layout="fullWidth"
                    // formats={["auto", "webp", "avif"]}
                    // aspectRatio={1}
                    // sizes="(max-width: 575px) calc(100vw - 24px), (max-width: 767px) 258px, (max-width: 991px) 348px, (max-width: 1199px) 228px, (max-width: 1399px) 273px, 318px"
                  />
                </Col>

                <Col xl={6}>
                  <div className="p-3 p-lg-4 text-end">
                    <h3 className="h4 text-start">
                      Farblicht­&shy;therapie mit dem PHOTONWAVE
                    </h3>
                    <p className="text-start">
                      Das Photonwave-Gerät ist unglaublich vielfältig in seinem
                      Anwendungs-Spektrum und bietet die Möglichkeit, sowohl auf
                      körperlicher als auch auf mentaler Ebene eine enorme
                      gesundheitliche Verbesserung zu erzielen. Durch das
                      Flackern von monochromatischen Farben wird über den
                      Sehnerv das limbische System aktiviert, das den Sitz der
                      Emotionen repräsentiert. Blockierte Synapsen im Gehirn
                      werden reaktiviert und unbewusste Traumen können besser
                      verarbeitet werden. Besonders erfolgreich eingesetzt wird
                      die Lichttherapie mit dem Photonwave zum Beispiel bei
                      Augenproblemen aller Art, bei Stress, um das Hormonsystem
                      wieder in Balance zu bringen, bei Migräne, psychischen
                      Konflikten, Hyperaktivität, Lese-/Rechtschreibschwäche,
                      Immunschwäche und bei Allergien. Ebenso gut kann das Gerät
                      zur Immunmodulation bei Belastung mit Viren, Bakterien und
                      Parasiten eingesetzt werden wie auch zur Entgiftung von
                      Schwermetallen und anderen Toxinen wie Glyphosat. Eine
                      Sitzung dauert rund 15 Minuten und ist eine optimale
                      Ergänzung zu den anderen Therapiemethoden, die ich
                      anbiete.
                    </p>
                    <Button
                      onClick={() =>
                        setShowSectionPhotonwave(!showSectionPhotonwave)
                      }
                      className="mb-3 custom-small-button"
                      variant="outline-primary"
                      size="sm"
                    >
                      {showSectionPhotonwave ? "Weniger" : "Mehr"}
                    </Button>
                    {showSectionPhotonwave && (
                      <>
                        <p className="text-start">
                          Von den amerikanischen Ärzten Dinshah P. Ghadiali und
                          Dr. Harry Riley Spittler wurde nach Jahren
                          detaillierter Forschung herausgefunden, dass das
                          Licht, das über die Augen in den Körper eindringt,
                          alle wichtigen Kontrollzentren des Gehirn, die die
                          Körperfunktionen steuern, erreichen kann. Da die
                          Augenfunktion direkt vom Autonomen Nervensystem
                          abhängig ist, war klar, dass diese Methode die
                          Visuelle Funktion verbesserte. Die Nebeneffekte waren
                          ebenso wichtig: mit bestimmten Farben erreichte man
                          eine Reduzierung bzw. einen Ausgleich von Stress,
                          sowohl physischen als auch emotionalen. Bemerkenswerte
                          Resultate bei spezifischen Beschwerdebildern:
                        </p>

                        <ul className="text-start">
                          <li>
                            Augenprobleme (Trockenheit, Sehschwäche,
                            Makuladegeneration, Nachblindheit,
                            Sehnerv-Erkrankungen, Glaukom, Keratitis etc)
                          </li>
                          <li>Immundysfunktion</li>
                          <li>Allergien</li>
                          <li>Traumata</li>
                          <li>Hauterkrankungen, Verbrennungen</li>
                          <li>Chronische Schmerzen</li>
                          <li>Chronisches Müdigkeitssyndrom</li>
                          <li>ADHD, Hyperaktivität, Leseschwäche</li>
                          <li>Depressionen</li>
                          <li>
                            Toxische Belastungen (Schwermetalle, Spritzmittel,
                            Viren, Bakterien, Parasiten)
                          </li>
                        </ul>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>

          <Col xs={12} className="d-flex align-items-stretch">
            <Container className="g-0 d-flex align-items-stretch bg-secondary">
              <Row className="g-0 w-100" id="darmgesundheit">
                <Col xl={6} className="d-flex align-items-stretch">
                  <StaticImage
                    src="../images/WEM_1932.jpg"
                    alt=""
                    placeholder="blurred"
                    objectFit="cover"
                    objectPosition="50% 50%"
                    className="w-100"
                    // breakpoints={[
                    //   228, 258, 273, 296, 318, 348, 351, 366, 456, 516, 546,
                    //   592, 636, 696, 702, 732,
                    // ]}
                    layout="fullWidth"
                    // formats={["auto", "webp", "avif"]}
                    // aspectRatio={1}
                    // sizes="(max-width: 575px) calc(100vw - 24px), (max-width: 767px) 258px, (max-width: 991px) 348px, (max-width: 1199px) 228px, (max-width: 1399px) 273px, 318px"
                  />
                </Col>

                <Col xl={6}>
                  <div className="p-3 p-lg-4">
                    <h3 className="h4 text-background">
                      Ernährungsberatung für Darmgesundheit, Entgiftung und
                      Wohlfühlgewicht
                    </h3>
                    <p className="text-background">
                      Gesundheit und Erholung hängen stark mit einer intakten
                      Darmflora und Darmtätigkeit zusammen. Aus dem
                      Gleichgewicht geraten macht sich der Verdauungstrakt mit
                      ganz unangenehmen Symptomen wie Krämpfen, Durchfall,
                      Verstopfung und Blähungen bemerkbar. Auch Gewichtszunahme
                      oder Abmagerung können die Folge eines krankhaften
                      Mikrobioms sein. Eine professionelle Ernährungstherapie
                      bringt vieles wieder ins Lot. Zudem kann mit der richtigen
                      Ernährung und so genannten Power-Foods die Entgiftung und
                      Entschlackung angeregt werden, die Zellalterung
                      verlangsamt und Zellregeneration angeregt werden. Das Ziel
                      ist Ihr Wohlfühlgewicht, mehr Wohlbefinden und Energie und
                      ein «Wohlgefühl» im Bauch.
                    </p>
                    <h3 className="h4 text-background">
                      Ernährungsberatung online
                    </h3>
                    <p className="text-background">
                      Online Ernährungsberatung eignet sich besonders dann, wenn
                      Sie keine körperlichen Beschwerden haben, aber
                      Unterstützung bei den Thema Abnehmen durch gesunde,
                      Stoffwechsel und Entgiftung aktivierende Ernährung und
                      gesundem Kochen brauchen. Sie entscheiden, wann und wie
                      oft Sie Unterstützung brauchen.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>

      <Container className="py-5" id="spezialangebote">
        <Row>
          <Col>
            <h2 className="section-title">
              PAKET-Spezialangebote Ernährungsberatung
            </h2>
            <p className="lead">
              Entscheiden Sie sich für eines der folgenden Pakete, bekommen Sie
              zu jedem Termin eine Gratis-Behandlung mit dem Photonwave zur
              Aktivierung der Entgiftung.
            </p>

            <p>
              Hand aufs Herz: wie viele Diäten haben Sie schon ausprobiert? Wie
              oft haben Sie den guten Vorsatz ausgesprochen, «gesünder» zu
              essen? Wie viel Geld haben Sie bereits für vielversprechende
              Pillen und Pülverchen ausgegeben, die Ihnen schnelles Abnehmen,
              jüngeres Aussehen, mehr Energie und Konzentrationsvermögen
              versprochen haben? Hat es den gewünschten Effekt gebracht? Nein?
              Reden Sie sich bitte nicht ein, Sie schaffen das nicht!
            </p>
            <p>
              Es gibt viele Gründe, warum Abnehmen nicht klappt. Vielleicht
              steckt Ihr Körper in einer Regulationsstarre. Auch Blockade auf
              mentaler Ebene können verhindern, dass die Kilos nicht purzeln.
              Oder Sie haben einfach die falsche Strategie zum Abnehmen gewählt.
              Folgendes kann ich Ihnen anbieten:
            </p>
            {/* <p>
              Brauchen Sie nur etwas Unterstützung beim Abnehmen und
              Stoffwechsel-Aktivierung, eignet sich das Paket Ernährungsberatung
              light. Steht nicht (nur) die Reduktion des Gewichts im
              Vordergrund, sondern auch die Ausscheidung von belastenden
              Giftstoffen, empfehle ich zusätzlich das Paket Organ-Check und
              Ausleitung von Giftstoffen oder eine Leberreinigung mit Aufbau
              einer gesunden Darmflora. Auch bei sehr schlanken Personen können
              die Ausscheidungs- und Entgiftungsorgane verschlackt und träge
              geworden sind, weil ihnen zu viele Toxine zu schaffen machen.
            </p> */}
          </Col>
        </Row>

        <Row className="g-3 g-lg-4 my-3">
          <Col lg={4}>
            <div className="p-3 p-lg-4 d-flex flex-column h-100 bg-tertiary spezial-pakete">
              <h3 className="h4">
                {/* <span className="d-none d-lg-block">
                  <span className="d-none d-lg-block d-xl-none">
                    <br />
                  </span>
                  <br />
                  <br />
                </span> */}
                Ernährungs­&shy;beratung light als Hilfe zum Abnehmen und gesund
                Kochen
              </h3>
              <p>5 Termine, jeweils 60-90 Minuten</p>
              <ul>
                <li>Anamnese ihres gesundheitlichen Zustandes</li>
                <li>
                  Individuelle Beratung und Begleitung während der ganzen Zeit
                </li>
                <li>
                  Erarbeitung eines Ernährungsplans, Sportprogramms, sowie
                  Empfehlung der richtigen Nahrungsergänzungsmittel, falls
                  nötig.
                </li>
                <li>
                  Tipps für einfache, gesunde und schmackhafte Zubereitung von
                  nährstoffreichen Mahlzeiten.
                </li>
                <li>
                  Tipps für die richtigen Geräte in der Küche, die gesunde,
                  nährstoffreiche Zubereitung von Nahrungsmitteln erleichtern.
                </li>
              </ul>
            </div>
          </Col>

          <Col lg={4}>
            <div className="p-3 p-lg-4 d-flex flex-column h-100 bg-quinary spezial-pakete">
              <h3 className="h4">
                Ernährungs&shy;beratung mit Organ-Check und Ausleitung von
                Giftstoffen
              </h3>
              <p>8 Termine, jeweils 60-90 Minuten</p>
              <ul>
                <li>Anamnese ihres gesundheitlichen Zustandes</li>
                <li>
                  Individuelle Beratung und Begleitung während der ganzen Zeit
                </li>
                <li>
                  Erarbeitung eines Ernährungsplans, Sportprogramms, sowie
                  Empfehlung der richtigen Nahrungsergänzungsmittel, falls
                  nötig.
                </li>
                <li>
                  Tipps für einfache, gesunde und schmackhafte Zubereitung von
                  nährstoffreichen Mahlzeiten.
                </li>
                <li>
                  Tipps für die richtigen Geräte in der Küche, die gesunde,
                  nährstoffreiche Zubereitung von Nahrungsmitteln erleichtern.
                </li>
                <li>
                  <b>
                    Gezielte Behandlung und Unterstützung der Entgiftungs- und
                    Ausscheidungsorgane mit Biophotonen-Therapie oder
                    Neurobiologie nach Dr. Klinghardt
                  </b>
                </li>
              </ul>
            </div>
          </Col>

          <Col lg={4}>
            <div className="p-3 p-lg-4 d-flex flex-column h-100 bg-quaternary spezial-pakete">
              <h3 className="h4">
                Detox-Programm mit Leberreinigung, Darmaufbau und
                Ernährungs&shy;beratung
              </h3>
              <p>10 Termine, jeweils 60-90 Minuten</p>
              <ul>
                <li>Anamnese ihres gesundheitlichen Zustandes</li>
                <li>
                  Individuelle Beratung und Begleitung während der ganzen Zeit
                </li>
                <li>
                  Erarbeitung eines Ernährungsplans, Sportprogramms, sowie
                  Empfehlung der richtigen Nahrungsergänzungsmittel, falls
                  nötig.
                </li>
                <li>
                  Tipps für einfache, gesunde und schmackhafte Zubereitung von
                  nährstoffreichen Mahlzeiten.
                </li>
                <li>
                  Tipps für die richtigen Geräte in der Küche, die gesunde,
                  nährstoffreiche Zubereitung von Nahrungsmitteln erleichtern.
                </li>
                <li>
                  Gezielte Behandlung und Unterstützung der Entgiftungs- und
                  Ausscheidungsorgane mit Biophotonen-Therapie oder
                  Neurobiologie nach Dr. Klinghardt
                </li>
                <li>
                  <b>
                    Leberreinigung nach Dr. Dietrich Klinghardt, Anthony
                    Williams oder Andreas Moritz
                  </b>
                </li>
                <li>
                  <b>
                    Darmreinigung und Aufbau der Darmflora mit passenden Prä-
                    und Probiotika
                  </b>
                </li>
                <li>
                  <b>
                    Empfehlung von Phytotherapeutika, Tees, Tinkturen,
                    Schüsslersalzen, um die Darmreinigung zu unterstützen und
                    den Magen-Darm-Trakt zu stärken
                  </b>
                </li>
              </ul>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <p className="mt-3">
              <b>
                Paket Ernährungs­­beratung light als Hilfe zum Abnehmen und
                gesund Kochen:
              </b>{" "}
              Möchten Sie sich gesünder ernähren, Ihr Wohlbefinden steigern,
              brauchen Sie Unterstützung beim Abnehmen und bei der Aktivierung
              Ihres Stoffwechsels, eignet sich diese Variante.
            </p>

            <p>
              <b>
                Paket Ernährungsberatung mit Organ-Check und Ausleitung von
                Giftstoffen:{" "}
              </b>
              Steht nicht (nur) die Reduktion des Gewichts im Vordergrund,
              sondern auch die Ausscheidung von belastenden Giftstoffen,
              empfehle ich dieses Paket. Ein Schwerpunkt der Behandlungs-Methode
              nach Dr. Klinghardt ist die intra- und extrazelluläre Entgiftung
              der für unsere Gesundheit schlimmsten Toxine. Dazu gehören unter
              anderen Quecksilber, Aluminium, Blei und Glyphosat.
            </p>

            <p>
              <b>
                Paket Ernährungsberatung mit Detox-Programm, Leberreinigung und
                Darmaufbau:
              </b>{" "}
              Die Leber ist unser grösstes Entgiftungsorgan und leistet im Laufe
              der Jahre Gewaltiges! Auch bei sehr schlanken Personen können die
              Ausscheidungs- und Entgiftungsorgane verschlackt und träge
              geworden sind, weil ihnen zu viele Toxine zu schaffen machen.
              Möchten Sie Ihrer Leber eine Pause gönnen, sie entgiften und ihr
              besondere Aufmerksamkeit schenken, eignet sich dieses Paket.
            </p>
            <p>
              Die Pakete können auch individuell nach ihrem Bedürfnis
              zusammengestellt werden, falls Sie zum Beispiel mehr Gewicht auf
              die Entgiftung statt auf die Ernährung legen wollen. Ein
              Schwerpunkt der Behandlungs-Methode nach Dr. Klinghardt ist die
              intra- und extrazelluläre Entgiftung der für unsere Gesundheit
              schlimmsten Toxine. Dazu gehören unter anderen Quecksilber,
              Aluminium, Blei und Glyphosat.
            </p>
          </Col>
        </Row>
      </Container>

      <Container fluid={true} className="g-0">
        <Row className="justify-content-between align-items-end g-0">
          <Col xs={6} md={4} lg={3} xl={2}>
            <StaticImage
              src="../images/line-plant-footer-single.png"
              alt=""
              placeholder="tracedSVG"
              layout="fullWidth"
              // formats={["auto", "webp", "avif"]}
              // sizes="(max-width: 575px) calc(100vw - 24px), (max-width: 576px) calc(540px - 24px), (max-width: 768px) calc(720px - 24px), (max-width: 992px) calc(960px - 24px), (max-width: 1200px) calc(1140px - 24px), calc(1320px - 24px)"
            />
          </Col>

          <Col xs={6} md={4} lg={3} xl={2}>
            <StaticImage
              src="../images/line-plant-footer-group.png"
              alt=""
              placeholder="tracedSVG"
              layout="fullWidth"
              // formats={["auto", "webp", "avif"]}
              // sizes="(max-width: 575px) calc(100vw - 24px), (max-width: 576px) calc(540px - 24px), (max-width: 768px) calc(720px - 24px), (max-width: 992px) calc(960px - 24px), (max-width: 1200px) calc(1140px - 24px), calc(1320px - 24px)"
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default TherapiemethodePage;
